import {getMenuList} from "../../api.service";

export default {
    namespace:true,
    state:{
        menuList:[],
        menuTreeList:[]
    },
    getters:{

    },
    mutations:{
        setMenuList(state,data){
            state.menuList = data
        },
        setMenuTreeList(state,data){
            state.menuTreeList = data
        },
    },
    actions:{
        async getMenuLists({commit},username){
            let res = await getMenuList(username)
            commit('setMenuList',res.data)
            commit('setMenuTreeList',res.data)
        }
    }
}
