export default {
    namespace:true,
    state:{
        disabled:false
    },
    mutations:{
        setModalDisabled(state,data){
            state.disabled = data
            console.log(state.disabled);
        }
    },
    actions:{

    }
}
