import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
	meta:{
		title:'登录'
	}
  },
  {
    path: '/imageUpload',
    name: 'imageUpload',
    component:  () => import('../views/imageUpload.vue'),
	meta:{
		title:''
	}
  },{
    path: '/test',
    name: 'test',
    component:  () => import('../views/testA.vue'),
	meta:{
		title:''
	}
  }, {
    path: '/HdPucha',
    name: 'HDPucha',
    component: () => import('../views/ewmPucha.vue'),
    meta: {
        title: ''
    }
}, {
  path: '/dkzhuyao',
  name: 'Dkzhuyao',
  component: () => import('../views/dkzhuyao.vue'),
  meta: {
      title: ''
  }
},
  {
    path: '/index',
    name: 'Index',
    redirect:'/index',
    component: () => import('../layOut/LayOutIndex.vue'),
    children:[
      {
        path:'/index',
        name:'HomeIndex',
		    meta:{
		    	title:'首页'
		    },
        component:()=>import('../views/home/index.vue')
      },
    ]
  },
  {
    path:'/manager',
    name:'manager',
    // redirect:'/home/index',
    component: () => import('../layOut/LayOutIndex.vue'),
    children: [{
		  path:'/examine',
		  name:'Examine',
		    meta: {
		              keepAlive: true,
		  title:'普查详情'
		          },
		  component:()=>import('../views/examine/Examine.vue')
		},{
		  path:'/state',
		  name:'State',
		  meta: {
		      keepAlive: true,
		      title:'枯死木分布'
		},
		  component:()=>import('../views/examine/State.vue')
		},{
        path:'/qrcode',
        name:'qrCode',
        meta: {
            keepAlive: true,
            title:'二维码管理'
        },
        component:()=>import('../views/examine/qrCode.vue')
    },
    {
      path:'/list',
		  name:'list',
		    meta: {
        keepAlive: true,
        title:'综合除治'
      },
		  component:()=>import('../views/except/list.vue')
		},
    {
      path:'/listAmend',
		  name:'listAmend',
		    meta: {
        keepAlive: true,
        title: '除治修改'
      },
		  component:()=>import('../views/except/listAmend.vue')
		},
    {
      path:'/punching',
      name:'Punching',
        meta: {
          keepAlive: true,
          title:'打孔注药详情'
        },
      component:()=>import('../views/except/punching.vue')
    },
		{
		  path:'/admin',
		  name:'admin',
		    meta: {
		              keepAlive: true,
		   title:'二维码管理'
		          },
		  component:()=>import('../views/except/admin.vue')
		},
    // {
		//   path:'/taskManagement',
		//   name:'taskManagement',
		//   meta: {
		//     keepAlive: true,
		//     title:'任务管理'
		//   },
		//   component:()=>import('../views/except/taskManagement.vue')
		// },
		{
		  path:'/detail',
		  name:'detail',
		  component:()=>import('../views/except/detail.vue')
		},
		{
		  path:'/result',
		  name:'result',
		  component:()=>import('../views/except/result.vue')
		},
      {
        path:'use',
        name:'use',
		meta:{
			title:'用户管理'
		},
        component:()=>import('../views/manager/use.vue')
      },
      {
        path:'menu',
        name:'menu',
		meta:{
			title:'菜单管理'
		},
        component:()=>import('../views/manager/menu.vue')
      },
      {
        path:'ewm',
        name:'ewm',
        component:()=>import('../views/manager/ewm.vue')
      },
      {
        path:'project',
        name:'project',
		meta:{
			title:'项目管理'
		},
        component:()=>import('../views/manager/project.vue')
      },
      {
        path:'role',
        name:'role',
		meta:{
			title:'角色管理'
		},
        component:()=>import('../views/manager/role.vue')
      },
      {
        path:'worker',
        name:'worker',
		meta:{
			title:'工作组管理'
		},
        component:()=>import('../views/manager/worker.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
 //beforeEach是router的钩子函数，在进入路由前执行
  if(to.path==="/") return next()
  if(from.path==="/") return next()
  const tokenStr = sessionStorage.getItem("access_token")
  if(!tokenStr) return next('/')
 if (to.meta.title) {
  //判断是否有标题
  // console.log(to.meta.title)
  document.title = to.meta.title
 } else {
  document.title = '疫木除治'
 }
 next()
})
export default router
