<template>
<vue-scroll :ops="ops" style="width:100%;">
  <div id="app">
    <!-- <router-view v-if="isRouterAlive"></router-view> -->
    <keep-alive>
        <router-view v-if="$route.meta.keepAlive">
            <!-- 这里是会被缓存的视图组件，比如 page1,page2 -->
        </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive">
        <!-- 这里是不被缓存的视图组件，比如 page3 -->
    </router-view>
  </div>
 </vue-scroll>
</template>

<script>
  export default {
    name: 'app',
    provide(){
        return{
           reload:this.reload
        }
    },
    data(){
        return{
           isRouterAlive:true,
           ops: {
            vuescroll: {},
            scrollPanel: {},
            rail: {
              keepShow: true
            },
            bar: {
              hoverStyle: true,
              onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
              background: "#F5F5F5",//滚动条颜色
              opacity: 0.5,//滚动条透明度
              "overflow-y": "hidden"
            }
      }
        }
    },
    methods:{
        reload(){
           this.isRouterAlive =false;
           this.$nextTick(function(){
                this.isRouterAlive=true
           })
        },
    }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
