<template>
    <div class="login">
		<div class="img1">
			<Img />
			<!-- <img src="../assets/gunlun.png"> -->
		</div>
		<div class="top">
			疫木除治全流程追溯平台
      <!-- 松材线虫病疫情大数据分析系统V1.0 -->
		</div>
		<div class="form">
			<div class="title">
				欢迎登录
			</div>
			<div class="username">
				<div class="img">
					<img src="../assets/name_ic.png" >
				</div>
				<input @keyup.enter="goHome" type="text" v-model="username" placeholder="请输入用户名" />
			</div>
			<div class="password">
				<div class="img">
					<img src="../assets/password_ic.png" >
				</div>
				<input @keyup.enter="goHome" v-model="passwd" type="password" placeholder="请输入密码" />
			</div>
			<div class="button" @click="goHome">
				登录
			</div>
		</div>
		<div class="footer">
			<span>技术支持：山东祥辰科技集团有限公司</span>
			<span>鲁ICP13007948号-5</span>
		</div>
    </div>

</template>

<script>
// @ is an alias to /src
import Img from './components/img.vue'
export default {
  name: 'Login',
  data(){
    return{
      username:'',
      passwd:'',
	    isLoading:false,
	    videoSrc:""
    }
  },
  components:{
	Img
  },
  methods:{
    goHome(){
      this.$axios.post(this.api + '/auth/token',{
        username: this.username,
        password: this.passwd,
		isBlock: false
      }).then((res) =>{
            if(res.code !== 200){
              this.$message.error('用户名或密码错误')
            }else{
              if(res.code === 200){
				this.isLoading = true;
                console.log(res)
                sessionStorage.setItem('username',this.username)
                sessionStorage.setItem('access_token', res.data.token_type + ' ' + res.data.access_token)
                sessionStorage.setItem('refresh_token', res.data.refresh_token)
                sessionStorage.setItem('projectId', res.data.projectId)
                sessionStorage.setItem('projectName', res.data.projectName[0])
                sessionStorage.setItem('groupId', res.data.groupId)
                sessionStorage.setItem('modify', res.data.modify)
                sessionStorage.setItem('hyModifyEli', res.data.hyModifyEli)
                sessionStorage.setItem('modifyInv', res.data.modifyInv)
                sessionStorage.setItem('eliminationImport', res.data.eliminationImport)
                sessionStorage.setItem('groupName', res.data.groupName[0])
                this.$router.push({path:'/index'})
              }else {
                this.$message.error('登录失败')
              }
            }
          }
      )
    }
  }
}
</script>
<style scoped>
	.img{
		width: 66px;
		height: 67px;
		/* background-color: red; */
		margin-top: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.img1{
		position: absolute;
		left: 30%;
		top:33%;
		width: 18%;
		/* height: 450px; */
	}
	.footer{
		position: absolute;
		width: 100%;
		bottom: 40px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		/* align-items: center; */
		color: #fff;
		}
	.img1>img{
		width: 100%;
		height:auto;
	}
	input{
		height: 60px;
		margin-top: 8px;
		width: 280px;
		background-color: transparent;
		border: none;
		color: #32c5ff;
		}
	input:focus{
		outline:none
	}
	input::-webkit-input-placeholder {
	/* WebKit browsers */
	color: #32c5ff;
	}
	input:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #32c5ff;
	}
	input::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #32c5ff;
	}
	input:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	color: #32c5ff;
	}
	img{
		width: 29px;
		height: 29px;
	}
	.login{
		width: 100%;
		height: 100vh;
		background: url(../assets/login_bg.jpg);
		background-size: 100% 100%;
		position: relative;
	}
  /* @media screen and (max-width:1366px){
    .login {
      transform: scale(0.7);
    transform-origin: 0 0;
    }
  } */
	.top{
		width: 100%;
		height: 108px;
		background: url(../assets/logo_bg.gif) no-repeat;
		background-size: 100% 100%;
		color: #08e7e7;
		font-size: 40px;
    /* font-size: 28px; */
		line-height: 90px;
		font-weight: 500;
		letter-spacing: 4px;
	}
	.form{
		width: 464px;
		height: 452px;
		background: url(../assets/right_bg.png) no-repeat;
		background-size: 100% 100%;
		position: absolute;
		right: 15%;
		top: 30%;
	}
	.title{
		color: #32c5ff;
		font-size: 28px;
		margin-top: 46px;
	}

	.form .username{
		width:368px;
		height: 74px;
		background: url(../assets/input_bg.png) no-repeat;
		background-size: 100% 100%;
		margin-left: 50px;
		margin-top: 30px;
		display: flex;
	}
	.form .password{
		width:368px;
		height: 74px;
		background: url(../assets/input_bg.png) no-repeat;
		background-size: 100% 100%;
		margin-left: 50px;
		margin-top: 30px;
		display: flex;
	}
	.form .button{
		width: 147px;
		height: 71px;
		background: url(../assets/botton_bg.png) no-repeat;
		background-size: 100% 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #19ecff;
		font-size: 24px;
		font-weight: 400;
		margin-top: 40px;
		margin-left: 160px;
	}
	.button:hover{
		cursor: pointer;
	}
</style>

