import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import { message} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css';
import axios from "@/utils/request.js";
import moment from 'moment'
import VueAMap from 'vue-amap'
import locale from 'ant-design-vue/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import dataV from '@jiaminghi/data-view'
import highcharts from 'highcharts'
import highcharts3d from 'highcharts/highcharts-3d'
import HighchartsVue from 'highcharts-vue'
// element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


// 配置打印
import Print from 'vue-print-nb'
Vue.use(Print); //注册

// less
import less from 'less'
Vue.use(less)

// 复制
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// 滚动条
import vuescroll from "vuescroll";//引入vuescroll
import "vuescroll/dist/vuescroll.css";//引入vuescroll样式
Vue.use(vuescroll);//使用

  Vue.use(HighchartsVue)
  highcharts3d(highcharts)
  Vue.use(dataV)
  Vue.use(Viewer)
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: 'ba62eec5eb0944afb0ace1cd38e4e6da',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor','AMap.Geocoder','AMap.Geolocation','AMap.MarkerClusterer'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});
Vue.prototype.$axios = axios
//本地
// Vue.prototype.api='/api_request/plague/api'
//线上
Vue.prototype.api='/plague/api'
moment.locale('zh-cn')
Vue.prototype.$moment=moment
Vue.config.productionTip = false


Vue.use(Antd);
Vue.prototype.$message = message
message.config({
  top: '65px',
  duration: 3
});

Vue.filter('dateTime',(value)=>{
        if (!value) {
            return value
        } else {
            return moment(value).format('YYYY-MM-DD HH:mm:ss')
        }
    }
)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
